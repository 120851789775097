import { useState } from 'react';



// export default function Hamburger({toggleNavbar}) {

//   const [show, setShow] = useState(false)

//   const toggle = () => {
//     setShow(!show)
//   }

//   return (
   

//     <div className='bars navbar-link'>
//      <div onClick={toggle}
//       className='navbar-div no-decoration'
//     ><i className="fa-solid fa-bars"></i></div>
//   </div>
//   )

// }


export default function Hamburger({ toggleNavbar }) {
  return (
    <div className='bars navbar-link'>
    <button onClick={toggleNavbar} className="navbar-div no-decoration hammenu">
      ☰
    </button>
    </div>
  );
}
