import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import UploadVideo from "./UploadVideo";
import UploadAudio from "./UploadAudio";

// import FileInput from "./FileInput/index.jsx";

export default function StepEdit(props) {

  const navigate = useNavigate()

  const { steps, setSteps, stepIdParam } = props;

  // const [position, setPosition] = useState(0);

  // useEffect(() => {
  //   setPosition(
  //     steps.findIndex((step) => {
  //       return step._id === Number(stepIdParam);
  //     })
  //   );
  // }, []);

  let position = steps.findIndex((step) => {
    return step._id === Number(stepIdParam)
  })

  // console.log("position", position)
  

  const axiosPrivate = useAxiosPrivate()
  // console.log("step", step)

  //DB
  const levels = [
    { level: 1, level_title: "Beginner" }, 
    { level: 2, level_title: "Intermediate" }, 
    { level: 3, level_title: "Intermediate2" }, 
    { level: 4, level_title: "Advanced" }, 
    {level: 5, level_title: "Advanced2"}, 
    {level: 6, level_title: "Rueda"}, 
    {level: 7, level_title: "Rueda 2"}
  ]

  //URLS

  const EDIT_URL = `/steps/edit`;
  const STEPS_URL = `/steps`;

  // useEffect(() => {
  //   console.log("step edit data", pronunciation, video)
  // }, [pronunciation, video])
  

  // const handleInputState = (name, value) => {
  //   setData((prev) => ({ ...prev, [name]: value }));
  // };

  //Edit function

  const editStep = async (e) => {
    e.preventDefault()

    try {
      const body = { id, step_title, step_level_id, video, pronunciation };

      await axiosPrivate.put(
        EDIT_URL, body
      )
      axiosPrivate.get(STEPS_URL).then(function (res) {
        setSteps([...res.data]);
      });

      navigate(-1)

    } catch (err) {
      console.error(err.message)
    }
  }

  //States


  const id = steps[position]._id
  const [step_title, setStepTitle] = useState(steps[position].step_title);
  const [step_level_id, setStepLevelId] = useState(
    steps[position].step_level_id
  );
  const [pronunciation, setPronunciation] = useState("");
  const [video, setVideo] = useState("");


  return (
    <div>
      {/* <!-- Button to Open the Modal --> */}
      <button
        type="button"
        className="btn-no-btn"
        data-toggle="modal"
        data-target={`#editstepmodal${steps[position]._id}`}
      >
        <i className="fa-solid fa-pen-to-square"></i>
      </button>

      {/* <!-- The Modal --> */}
      <div className="modal" id={`editstepmodal${steps[position]._id}`}>
        <div className="modal-dialog">
          <div className="modal-content">

            {/* <!-- Modal Header --> */}
            <div className="modal-header">
              <h4 className="modal-title">Edit Step</h4>
             
              <button type="button" className="close" data-dismiss="modal">&times;</button>
             
            </div>
            {/* <p className="instructions --margin-10px">Currently, it is not possible to modify the video or audio.</p> */}

            {/* <!-- Modal body --> */}
            <div className="modal-body">

              <label className="edit-item" htmlFor="title">
                Name
              </label>
              <input
                className="form-control-add"
                type="text"
                // name="title"
                value={step_title}
                onChange={(e) => setStepTitle(e.target.value)}
              />

              <div className="level_input">
                <label className="edit-item" htmlFor="title">
                  Level
                </label>
                <select
                  className="form-control-add"
                  value={step_level_id}
                  onChange={(e) => setStepLevelId(e.target.value)}
                >
                  {levels.map((level) => (
                    <option
                      key={level.level}
                      value={level.level}
                    >
                      {level.level_title}
                    </option>
                  ))}
                </select>
              </div>

              <label className="edit-item" htmlFor="title">
                  Video
                </label>
              <UploadVideo setVideo={setVideo}/>

              <label className="edit-item" htmlFor="title">
                  Audio
                </label>
              <UploadAudio setPronunciation={setPronunciation}/>

              {/* <FileInput
                name="video"
                label="Choose Video"
                handleInputState={handleInputState}
                type="video"
                value={step.step_video}
                // value={''}
              />
              <FileInput
                name="pronunciation"
                label="Choose Audio File"
                handleInputState={handleInputState}
                type="audio"
                value={step.step_pronunciation}
                // value={''}
              /> */}



            </div>

            {/* <!-- Modal footer --> */}
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
              <button
                type="button"
                className="btn button-black"
                data-dismiss="modal"
                onClick={e => editStep(e)}
              >Edit</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
