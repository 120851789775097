import DeleteAllStepsFromBin from "./DeleteAllStepsFromBin";
import RecycleAllSelectedSteps from "./RecycleAllSelectedSteps";

export default function ConfirmRecycleAllSelectedSteps(props) {

  const { step, steps, setSteps } = props;

  return (
    <div>
      {/* <!-- Button to Open the Modal --> */}
      <button
        type="button"
        title="Delete selected"
        className="delete-selection btn-no-btn"
        data-toggle="modal"
        data-target={`#confirmrecycleallselectedstepsmodal`}
      >
         {/* <i className="fa-solid fa-trash-can-arrow-up"></i> */}
        {/* Delete selected */}
        <i className="fa-regular fa-rectangle-xmark"></i>
      </button>

      {/* <!-- The Modal --> */}
      <div className="modal" id={`confirmrecycleallselectedstepsmodal`}>
        <div className="modal-dialog">
          <div className="modal-content">

            {/* <!-- Modal Header --> */}
            <div className="modal-header">
              <h4 className="modal-title">Confirm</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            {/* <!-- Modal body --> */}
            <div className="modal-body">
              Send the selected steps to the recycling bin?

            </div>

            {/* <!-- Modal footer --> */}
            <div className="modal-footer">

              <RecycleAllSelectedSteps setSteps={setSteps}/>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
