import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import CheckboxAutoplpay from './CheckboxAutoplay';

export default function Profile() {

  


  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();

  const { auth } = useAuth();

  const id = auth.userId;
  //console.log("id", id)
  const { userId } = useParams();
  //  console.log("userId", userId)

  const [userInfo, setUserInfo] = useState({ roles: "" });

  // useEffect(()=> {
  //   console.log("userInfo", userInfo._id)
  // })

  const ONEUSER_URL = `/users/${userId}`

  //Get user with username

const [autoplay, setAutoplay] = useState("");
const [automute, setAutomute] = useState("");
const [loop, setLoop] = useState("");


  useEffect(() => {
    if (userId !== "undefined") {
      axiosPrivate.get(ONEUSER_URL).then(
        function (res) {
          setUserInfo(res.data[0])
          setAutoplay(res.data[0].autoplay)
          setAutomute(res.data[0].automute)
          setLoop(res.data[0].loop)
          
        }
      )
    }
  }, []);

  // console.log("auth id", id)

  return (

    //Server was crashing when token expired and try to access profile, since de userId was undefined. Then, I realized that if someone wrote /users/***random number***, the serve would crash as well, so I solved it with line 48.

    <section>
      {userId !== "undefined" && id == userInfo._id ? (
        <div>
          <div
            className='level-title text-align-center'
          >Profile page</div>
          <div className='text-align-center wrap'>
            Name:&nbsp;
            {userInfo.username}
            <br />
            Email:&nbsp;
            {userInfo.email}
            <br />
            Level:&nbsp;
            {Object.keys(userInfo.roles).pop()}
            <br />

          </div>
          <></>
          <div className='level-title text-align-center'>Soon available:</div>


          <div className='text-align-center wrap'>
            {/* <CheckboxAutoplpay /> */}
            <input type="checkbox" disabled></input>
            Autoplay videos
            <br />
            {/* CheckboxAutomute */}
            <input type="checkbox" checked disabled></input>
            Automute videos
            <br />
            <input type="checkbox" checked disabled></input>
              {/* CheckboxLoop */}
            Loop
            <br />


          </div>

        </div>

      ) : (


        // {signOut}
        <section>
          <p>
            You might have been logged out for security reasons.
            <br />
            <br />
            If available, access the profile page from the navigation bar.
            <br />
            <br />
            Otherwise, please&nbsp;
            <a href="/login">Sign In</a>
          </p>
        </section>

        // navigate("/login")

        // navigate('/login', { replace: true })

      )}
    </section>
  )
}

