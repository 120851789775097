//Images
import stepslogo from './img/stepslogo.png';

import { Link } from "react-router-dom"

const Home = () => {

    return (
        <section className="section-home">
            <div className="home-header">
            <img className='home-logo-img' src={stepslogo} alt="Steps! logo" />

                <video
                    className="video welcome-video"
                    title="welcome video"
                    controls="controls"
                    autoPlay="autoplay"
                    // height="150px"
                    loop="loop"
                    preload="auto"
                    // width="300px"
                    muted="muted"
                    // src={`https://firebasestorage.googleapis.com/v0/b/casinosteps-de217.appspot.com/o/videos%2Fexample.mp4?alt=media&token=${process.env.REACT_APP_TOKEN_VIDEO_HOME}`}
                    src={`https://casinosteps.ca/videos/home-video.mp4`}
                ></video>
            </div>

            <div className="home-body">
            <Link to="/login" className="link-black">Login</Link> to review the steps learned in class!
            </div>


            <div className="home-footer">
                <div className="footerp">Steps! by&nbsp;
                
                <a className="home-ext-link link-black" href="https://www.vancuba.com/" rel="noreferrer noopener" target="_blank"> <strong>Julio Montero</strong></a>  &nbsp;
                </div>

                <div className="footerp">Developed by&nbsp;
                
                <a className="home-ext-link link-black" href="https://www.manucasanova.com/" rel="noreferrer noopener" target="_blank"> <strong>M. Casanova</strong></a>  &nbsp;</div>
            </div>


        </section>
    )
}

export default Home
