// import { useEffect, useState } from "react";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useState } from 'react';


export default function UserEdit(props) {

  const { user, setUsers, beginnersUsers, setBeginnersUsers } = props;
  //console.log(users)

  // console.log(user)

  // console.log(    users.map((user, i) => {
  //   return user.roles
  // }))

  const axiosPrivate = useAxiosPrivate()

  //DB
  const userroles = [
    { id: 1, userrole: "Beginner", role: { Beginner: 2001 }},
    { id: 2, userrole: "Intermediate", role: { Beginner: 2001,  Intermediate: 2002 }},
    { id: 3, userrole: "Intermediate2", role: { Beginner: 2001,  Intermediate: 2002, Intermediate2:2003 }},
    { id: 4, userrole: "Advanced", role: { Beginner: 2001,  Intermediate: 2002, Intermediate2: 2003, Advanced: 2004 }},
    { id: 5, userrole: "Advanced2", role: { Beginner: 2001,  Intermediate: 2002, Intermediate2: 2003, Advanced: 2004, Advanced2: 2005 } },
    { id: 6, userrole: "Administrator", role: { Beginner: 2001,  Intermediate: 2002, Intermediate2: 2003, Advanced: 2004, Advanced2: 2005, Admin: 2006 } }
  ]




  //URLS

  const EDIT_URL = `/users/edit`;
  const USERS_URL = `/users`;


  //States


  const id = user._id
  const [roles, setRoles] = useState(user.roles); // Initialize roles with the user's current roles

  //Edit function

  const editUser = async (e) => {
   e.preventDefault()
    
    try {
      
      const body = {
        id, roles
      };
      // console.log("body front", body)

      await axiosPrivate.put(
        EDIT_URL, body
      )
      axiosPrivate.get(USERS_URL).then(function (res) {



        const sortedArray = res.data.slice().sort((a, b) => {
          const getLastNumber = (obj) => {
              const roles = obj.roles;
              const roleKeys = Object.keys(roles);
              const numbers = roleKeys.map(key => roles[key]);
              return Math.max(...numbers);
          };

          return getLastNumber(a) - getLastNumber(b);
      });

        setUsers(sortedArray);
        
      });
    } catch (err) {
      console.error(err.message)
    }
  }


  const setAndEdit = async (e) => {
    setRoles(e.target.value);
    
  }



  return (
    <div className='users-level margind-padding-zero'>
      {/* <!-- Button to Open the Modal --> */}
      <button
        title="Click to edit"
        type="button"
        className="btn-no-btn"
        data-toggle="modal"
        data-target={`#userlevelquickeditmodal${user._id}`}
      >
        {Object.keys(user.roles).pop()}
      </button>

      {/* <!-- The Modal --> */}
      <div className="modal" id={`userlevelquickeditmodal${user._id}`}>
        <div className="modal-dialog">
          <div className="modal-content">

                        {/* <!-- Modal Header --> */}
                        <div className="modal-header">
              <h4 className="modal-title">Edit level</h4>
              {/* <button type="button" className="close" data-dismiss="modal">&times;</button> */}
            </div>

            {/* <!-- Modal body --> */}
            

              <div className="level_input --margin-bottom-100px">
                <select
                  className="form-control-add"
                  value={roles}
                  onChange={(e) => setAndEdit(e)}
                >
                  {userroles.map((role) => (
                    <option
                      key={role.id}
                      value={JSON.stringify(role.role)}
                    >
                      {role.userrole}
                      {/* {console.log(role.role)} */}
                    </option>
                  ))}
                </select>
              </div>

          

            {/* <!-- Modal footer --> */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn button-black"
                data-dismiss="modal"
                onClick={editUser}
              >Edit</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
