import { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useAxiosPrivate from '../hooks/useAxiosPrivate';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;

export default function UserEditName(props) {

  const { user, setUsers } = props;

  const axiosPrivate = useAxiosPrivate()



  //URLS

  const EDIT_URL = `/users/edit`;
  const USERS_URL = `/users`;


  //States


  const id = user._id

  const userRef = useRef();
  const errRef = useRef();

  const [username, setUsername] = useState(user.username);
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');


  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setValidName(USER_REGEX.test(username));
  }, [username])

  useEffect(() => {
    setErrMsg('');
  }, [user])

  //Edit function

  const editUsername = async (e) => {
    e.preventDefault()

    try {
      const body = {
        id, username
      };
      // console.log("body front", body)

      await axiosPrivate.put(
        EDIT_URL, body
      )
      axiosPrivate.get(USERS_URL).then(function (res) {
        setUsers([...res.data]);


      });
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else if (err.response?.status === 403) {
        setErrMsg('Changes restricted for this user by the developer');
      } else if (err.response?.status === 409) {
        setErrMsg('Username already exists');
      } else {
        setErrMsg('Operation Failed');
      }
      errRef.current.focus();
    }
  }


  return (
    <div>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
      {/* <!-- Button to Open the Modal --> */}
      <button
        title="Click to edit"
        type="button"
        className="btn-no-btn break-word"
        data-toggle="modal"
        data-target={`#editusernamemodal${user._id}`}
      // data-backdrop="static"
      >
        {user.username}
      </button>

      {/* <!-- The Modal --> */}
      <div className="modal" id={`editusernamemodal${user._id}`}>
        <div className="modal-dialog">
          <div className="modal-content">

            {/* <!-- Modal Header --> */}
            <div className="modal-header">
              <h4 className="modal-title">Edit Username</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            {/* <!-- Modal body --> */}
            <div className="modal-body">

              <label htmlFor="username" 
              className="edit-item">
                {/* Username */}
                <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
              </label>
              <input
                className="form-control-add"
                type="text"
                // name="title"
                id={username}
                ref={userRef}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                aria-invalid={validName ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setUserFocus(true)}
                onBlur={() => setUserFocus(false)}
              />
              <p id="uidnote" className={userFocus && username && !validName ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                4 to 24 characters.<br />
                Must begin with a letter.<br />
                Letters, numbers, underscores, hyphens allowed.
              </p>


            </div>

            {/* <!-- Modal footer --> */}
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
              <button
                disabled={!validName ? true : false}
                type="button"
                className="btn button-black"
                data-dismiss="modal"
                onClick={e => editUsername(e)}
              >Edit</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
