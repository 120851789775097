import StepsRueda2 from "./StepsRueda2";

export default function Level_Rueda2({steps, setSteps}) {
  return (
    <section>
      <div className="level-title">Rueda 2</div>
      <br />
      
      <StepsRueda2 steps={steps} setSteps={setSteps}/>

{/* <div style={{ textAlign: 'center', marginTop: '2em'}}>We are working on bringing you new, cool, advanced Rueda moves!</div> */}

    </section>
  )
}