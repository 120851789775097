import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';


import styles from "./Songs/styles.module.css";

import StepEdit from "./StepEdit";



export default function OneStepView({ steps, setSteps }) {



// Nuevo



const axiosPrivate = useAxiosPrivate();
const [userInfo, setUserInfo] = useState({ roles: "" });
const [userId, setUserId] = useState("")
const ONEUSER_URL = `/users/${userId}`


const [autoplay, setAutoplay] = useState("");
const [automute, setAutomute] = useState("");
const [loop, setLoop] = useState("");

 console.log("autoplay", autoplay)

  useEffect(() => {
    if (userId !== "undefined") {
      axiosPrivate.get(ONEUSER_URL).then(
        function (res) {
          setUserInfo(res.data[0])
          setAutoplay(res.data[0].autoplay)
          setAutomute(res.data[0].automute)
          setLoop(res.data[0].loop)
          
        }
      )
    }
  }, []);

// Fin Nuevo


  const { auth } = useAuth()
// console.log("authhh", auth)
// console.log("us objc auth id", auth.userId);
  let { stepIdParam } = useParams();

  const [position, setPosition] = useState(0);

  useEffect(() => {
    setPosition(
      steps.findIndex((step) => {
        return step._id === Number(stepIdParam);
      })
    );
  }, []);

  const navigate = useNavigate();
  const location = useLocation();


  //At first the array is empty. Then, because tools is inside [] useEffect...
  if (steps[position]) {
    return (

      <section className="section-one-step-view">

        <button
        className="close close-one-view"
        onClick={() => navigate(-1)}
        >&times;</button>

        <div className="one-step-view">
          <div className="one-step-view-header">
            {steps[position].step_title}
          </div>

          {steps[position].step_pronunciation !== '' ? 
          <div className="one-step-view-audio">
            <div className="audio">
              <audio className={styles.audio} src={steps[position].step_pronunciation} controls />
            </div>
          </div>
  : <div className="no-media-file">We don't have yet an audio file with this step's pronunciation.</div> }


          <div className="one-step-view-video">

{steps[position].step_video !== '' ? 
          <video
          className="video"
          title={`${steps[position].step_title} video`}
          controls="controls"
          autoplay={autoplay ? "autoplay" : undefined}
          //  height="100%"
          loop={loop ? "loop" : undefined}
          preload="auto"
          // width="100%"
          muted={automute ? "muted" : undefined}
          src={steps[position].step_video}
        ></video>
: <div className="no-media-file">We don't have yet a video file for this step.</div> }





          </div>
        
            
          {Object.keys(auth).length && Object.values(auth.roles).pop() === 2006
            ? <StepEdit steps={steps} stepIdParam={stepIdParam} setSteps={setSteps}/>
            : <></>
          }
            
          
        </div>

      </section>




    )
  };
}




// import { useParams } from "react-router-dom";

// import '../css/video.css'
// import styles from "./Songs/styles.module.css";


// export default function OneStepView({ step  }) {


//   return (

//     <section>


//       <div className="one-step-view">
//         <div className="one-step-view-header">

//           <div className="modal-step-title">
//             {step.step_title}
//           </div>
//           <div className="close-one-step-view">

//           </div>

//         </div>

//         <div className="one-step-view-audio">
//           <div className="audio">
//             <audio className={styles.audio} src={step.step_pronunciation} controls />
//           </div>
//           <div className="alt-word">

//             <a
//               title="Click to get an alternative media"
//               className="alt-word margin-left-10px"
//               target="_blank"
//               rel="noopener noreferrer"
//               href={`${step.step_pronunciation}`}>
//               alt</a>
//           </div>
//         </div>

//         <div className="one-step-view-video">
//           <div className="video">
//             <video
//               className="zindex1"
//               title={`${step.step_title} video`}
//               controls="controls"
//               // autoplay="autoplay"
//               height="300px"
//               // loop="loop"
//               preload="auto"
//               width="100%"
//               muted="muted"
//               src={step.step_video}
//             ></video>

//           </div>
//           <div className="alt-word">
//             <a
//               title="Click to get an alternative media"
//               className="alt-word"
//               target="_blank"
//               rel="noopener noreferrer"
//               href={`${step.step_video}`}>alt</a>
//           </div>
//         </div>

//       </div>

//     </section>


//   //     {/* <!-- The Modal --> */ }
//   // <div className="modal" id={`oneviewmodal${step._id}`}>
//   //   <div className="modal-dialog">
//   //     <div className="modal-content one-step-modal ">

//   //       {/* <!-- Modal Header --> */}
//   //       <div className="modal-header modal-step-title">
//   //         {/* <h4 className="modal-title">Edit Step</h4> */}
//   //         {step.step_title}



//   //         <button type="button" className="close" data-dismiss="modal">&times;</button>
//   //       </div>

//   //       {/* <!-- Modal body --> */}
//   //       <div className="modal-body">

//   //         <div className="media-alt">
//   //           <audio className={styles.audio} src={step.step_pronunciation} controls />
//   //           <a
//   //             title="Click to get an alternative media"
//   //             className="alt-word margin-left-10px"
//   //             target="_blank"
//   //             rel="noopener noreferrer"
//   //             href={`${step.step_pronunciation}`}>
//   //             alt</a>

//   //         </div>

//   //         <br />


//   //         <video
//   //           className="zindex1"
//   //           title={`${step.step_title} video`}
//   //           controls="controls"
//   //           // autoplay="autoplay"
//   //           height="300px"
//   //           // loop="loop"
//   //           preload="auto"
//   //           width="100%"
//   //           muted="muted"
//   //           src={step.step_video}
//   //         ></video>
//   //         <a
//   //           title="Click to get an alternative media"
//   //           className="alt-word"
//   //           target="_blank"
//   //           rel="noopener noreferrer"
//   //           href={`${step.step_video}`}>alt</a>

//   //       </div>

//   //       {/* <!-- Modal footer --> */}
//   //       <div className="modal-footer">


//   //       </div>

//   //     </div>
//   //   </div>
//   // </div>
//   //   </div >
//   )

// }